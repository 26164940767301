// export const SET_BLOG_DATA = 'SET_BLOG_DATA';
// export const SET_BLOG_LOADING = 'SET_BLOG_LOADING';
// export const SET_FEATURED_BLOGS = 'SET_FEATURED_BLOGS';
// export const SET_SEARCH_BLOGS = 'SET_SEARCH_BLOGS';
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA';
export const SET_PROJECT_LOADING = 'SET_PROJECT_LOADING';
export const SET_FEATURED_PROJECT = 'SET_FEATURED_PROJECT';
export const SET_TEAM_DATA = 'SET_TEAM_DATA';
export const SET_TEAM_LOADING = 'SET_TEAM_LOADING';
// export const SET_INSIGHTS_BLOGS = 'SET_INSIGHTS_BLOGS';
