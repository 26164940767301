import React from 'react';

const TwitterIcon = ({ width = 24, height = 24, fill = '', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            id="Group_5699"
            data-name="Group 5699"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 51.478 42"
        >
            <defs>
                <linearGradient id="linear-gradient" x1="1" x2="0.118" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#29c2e2" />
                    <stop offset="1" stopColor="blue" />
                </linearGradient>
                <clipPath id="clip-path">
                    {fill ? (
                        <rect
                            id="Rectangle_1070"
                            data-name="Rectangle 1070"
                            width="51.478"
                            height="42"
                            transform="translate(0 0)"
                            fill="#cfcfcf"
                        />
                    ) : (
                        <rect
                            id="Rectangle_1034"
                            data-name="Rectangle 1034"
                            width="53.354"
                            height="44.375"
                            fill="url(#linear-gradient)"
                        />
                    )}
                </clipPath>
            </defs>
            <g id="Group_5869" data-name="Group 5869" transform="translate(0 0.003)">
                <g id="Group_5869-2" data-name="Group 5869" transform="translate(0 -0.003)">
                    <path
                        id="Path_1088"
                        data-name="Path 1088"
                        d="M15.508,32.866A10.779,10.779,0,0,1,8.223,29.7,10.3,10.3,0,0,1,5.7,25.535a1.107,1.107,0,0,1,.176,0,10.43,10.43,0,0,0,4.316-.166.545.545,0,0,0,.167-.075,10.639,10.639,0,0,1-7.383-5.822,10.011,10.011,0,0,1-1.035-4.667c.277.132.517.253.763.361s.5.214.756.306.529.179.8.253.527.134.794.185.536.09.806.122.536.048.825.073c-.027-.12-.106-.146-.164-.187a10.585,10.585,0,0,1-4.4-7.335,10.473,10.473,0,0,1,1.2-6.41c.036-.066.076-.129.134-.226A30.82,30.82,0,0,0,7.924,6.39a30.176,30.176,0,0,0,5.3,3.42A30.412,30.412,0,0,0,19.1,12.047a28.749,28.749,0,0,0,6.22.925c-.009-.065-.013-.133-.027-.2a10.228,10.228,0,0,1-.225-2.473A10.627,10.627,0,0,1,32.377.515a10.091,10.091,0,0,1,2.5-.49,10.464,10.464,0,0,1,7.3,2.194,12.764,12.764,0,0,1,1.059.948.4.4,0,0,0,.4.127,21.878,21.878,0,0,0,4.216-1.353A21.554,21.554,0,0,0,49.945.884C49.987.86,50.03.84,50.118.8A10.539,10.539,0,0,1,48.335,4.1a10.719,10.719,0,0,1-2.773,2.452l.012.041a1.591,1.591,0,0,0,.212,0A21.236,21.236,0,0,0,51.259,5.1c.051-.022.1-.043.155-.062a.268.268,0,0,1,.063,0,1.521,1.521,0,0,1-.076.142,21.056,21.056,0,0,1-2.147,2.645,20.8,20.8,0,0,1-2.79,2.46.417.417,0,0,0-.184.4c.018.69.015,1.38.006,2.071,0,.348-.037.7-.058,1.044a27.292,27.292,0,0,1-.434,3.4,31.509,31.509,0,0,1-2.822,8.356,30.708,30.708,0,0,1-4.76,6.954,28.515,28.515,0,0,1-9.078,6.708,29.015,29.015,0,0,1-7.224,2.273,31.865,31.865,0,0,1-3.569.43c-.954.061-1.909.089-2.865.064A30.069,30.069,0,0,1,.394,37.573,3.874,3.874,0,0,1,0,37.306a21.469,21.469,0,0,0,12.353-2.362,18.35,18.35,0,0,0,3.155-2.078"
                        transform="translate(0 0.003)"
                        fill={fill ? fill : 'url(#linear-gradient)'}
                    />
                </g>
            </g>
        </svg>
    );
};

export default TwitterIcon;
